<template>
  <v-card flat tile>
    <v-window v-model="onboarding">
      <v-window-item key="card1">
        <v-card>
          <v-card-title>Cześć!</v-card-title>
          <v-card-text>
            <p>
              To Twoje pierwsze logowanie do Portalu. Zapoznaj się z krótkim
              przewodnikiem!
            </p></v-card-text
          ></v-card
        >
      </v-window-item>

      <v-window-item key="card2">
        <v-card>
          <v-card-title>Lista zadań</v-card-title>
          <v-card-text>
            <p>
              W widoku <i>Lista zadań</i> znajdziesz zadania z aktualnego
              tygodnia. Zrobione zadania są zaznaczane <i>ptaszkiem</i>.
            </p>

            <v-list flat subheader three-line>
              <v-list-item-group multiple>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox input-value="true" readonly></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Zrobione zadanie</v-list-item-title>
                    <v-list-item-subtitle
                      >...wygląda właśnie w ten sposób!</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox disabled></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Niezrobione zadanie</v-list-item-title>
                    <v-list-item-subtitle
                      >...wygląda właśnie w ten sposób!</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item key="card3">
        <v-card>
          <v-card-title>Statystyki</v-card-title>
          <v-card-text>
            <p>
              Po wykonaniu dwóch ćwiczeń, uzyskasz dostęp do <i>Statystyk</i>.
              Sprawdź je koniecznie!
            </p>
            <v-card max-height="400px" elevation="0">
              <v-sparkline
                :value="value"
                :gradient="gradient"
                :smooth="3"
                :padding="padding"
                :line-width="width"
                :gradient-direction="gradientDirection"
                :fill="fill"
                :type="type"
                :auto-line-width="autoLineWidth"
                auto-draw
              ></v-sparkline>
            </v-card>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item key="card4">
        <v-card>
          <v-card-title>Archiwum</v-card-title>
          <v-card-text>
            <p>
              Wszytkie wykonane i niewykonane ćwiczenia z poprzednich tygodni
              znajdziesz w <i>Archiwum</i>.
            </p>

            <v-row align="center" justify="center">
              <v-card
                width="100px"
                height="100px"
                class="text-center"
                elevation="0"
              >
                <v-icon x-large style="font-size: 90px" color="primary"
                  >mdi-folder-open</v-icon
                >
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item key="card5">
        <v-card>
          <v-card-title>Pliki cookies</v-card-title>
          <v-card-text>
            <p>
              Serwis niniejszy wykorzystuje do prawidłowego działania pliki
              cookies. Kontynuacja oznacza akcjeptację.
            </p>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item key="card6">
        <v-card>
          <v-img class="white--text align-end" src="../assets/mountains.jpg">
            <v-card-title>Dziękujemy!</v-card-title>
            <v-card-text>
              <p>
                Mamy nadzieję, że korzystanie z tej aplikacji pomoże Ci
                przygotować się do Egzaminu Ósmoklasisty!
              </p>
              <v-btn elevation="0" @click="finish">Koniec</v-btn>
            </v-card-text>
          </v-img>
        </v-card>
      </v-window-item>
    </v-window>

    <v-card-actions class="justify-space-between" v-if="onboarding != 5">
      <v-btn text @click="prev"> Wstecz </v-btn>
      <v-item-group v-model="onboarding" class="text-center" mandatory>
        <v-item
          v-for="n in length"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
        >
          <v-btn :input-value="active" icon @click="toggle">
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn text @click="next"> Dalej </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  data: () => ({
    length: 6,
    onboarding: 0,
    width: 2,
    radius: 10,
    padding: 8,
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 10],
    gradientDirection: "bottom",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,
  }),

  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    finish() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.color-card {
  background: linear-gradient(
    90deg,
    rgba(72, 0, 254, 1) 0%,
    rgba(131, 58, 180, 1) 100%
  );
}
</style>
