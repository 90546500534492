<template>
  <div class="dashboard">
    <v-dialog v-model="welcomeDialog" max-width="600px">
      <Welcome @close="welcomeDialog = false" />
    </v-dialog>
    <v-card>
      <v-img
        class="white--text align-end"
        height="100px"
        src="https://picsum.photos/1000/100"
        style="background-color: dimgrey"
      >
        <v-card-title
          >Miło Cię widzieć{{
            displayName && `, ${displayName}`
          }}!</v-card-title
        >
      </v-img>
    </v-card>

    <v-btn class="mt-3" @click="enterCodeDialog = true"
      ><v-icon dark left> mdi-qrcode </v-icon>Wprowadź kod...
    </v-btn>

    <v-card class="mt-3">
      <v-card-title>Zadania powtórzeniowe</v-card-title>

      <v-card-text>
        <p>
          Powtórz materiał omawiany podczas zajęć stacjonarnych
        </p>
        <TaskList
          :tasks="taskList"
          :week="currentWeekNumber"
          :loading="tasksLoading"
          :group="group"
        />
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-card-title>Fiszki</v-card-title>

      <v-card-text>
        <p>
          Oprócz wykonania powyższych zadań, proponujemy powtórzenie kilkunastu
          słówek z angielskiego.
        </p>
        <TaskList
          :tasks="flashcardList"
          :week="currentWeekNumber"
          :loading="tasksLoading"
          flashcards
          class="mt-2"
        />
      </v-card-text>
    </v-card>

    <v-dialog v-model="enterCodeDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Wprowadź kod...</span>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-text-field
            label="Wprowadź kod..."
            @keyup="uppercase"
            placeholder="XXXXXX"
            outlined
            v-model="exerciseCode"
          ></v-text-field>
          <small
            >Kod znajduje się na dole karty pracy. Alternatywnie, możesz
            zeskanować kod QR.</small
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="getExerciseById">
            Zobacz ćwiczenie
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TaskList from "../components/TaskList.vue";
import db from "@/firebase/init";
import firebase from "firebase";

import { mapMutations, mapState } from "vuex";
import Welcome from "../components/Welcome.vue";

// RemoteConfig setup
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  weeks: [],
};

export default {
  name: "Dashboard",
  computed: mapState(["isRodoAdmin"]),
  components: { TaskList, Welcome },
  data() {
    return {
      exerciseCode: "",
      enterCodeDialog: false,
      currentWeekNumber: 0,
      displayName: firebase.auth().currentUser.displayName,
      uid: "",
      taskList: [],
      flashcardList: [],
      tasksLoading: true,
      name: "",
      welcomeDialog: false,
      group: "",
    };
  },
  methods: {
    ...mapMutations([
      "changeWeeks",
      "changeDoneTasks",
      "changeScores",
      "changeGroup",
    ]),
    getExerciseById() {
      this.uppercase();
      this.$router.replace({
        name: "Learning",
        params: { id: this.exerciseCode },
      });
    },
    redirectToRodoPanel() {
      this.$router.replace({ name: "ShowMembers" });
    },
    uppercase() {
      this.exerciseCode = this.exerciseCode.toUpperCase();
    },
  },
  mounted() {
    this.uid = firebase.auth().currentUser.uid;

    // check what week is
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        this.weeks = JSON.parse(remoteConfig.getString("weeks"));
        this.currentWeekNumber = this.weeks.length;
        this.changeWeeks(this.weeks);
        // get taskList
        let docRef = db
          .collection("weeks")
          .doc(this.weeks[this.currentWeekNumber - 1]);
        docRef
          .get()
          .then((doc) => {
            this.taskList = doc.data().taskList;
            this.flashcardList = doc.data().flashcardList;
            this.tasksLoading = false;
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(() => {
        alert(
          "Przepraszamy. Wystąpił błąd pobierania danych dotyczących aktualnego tygodnia. Wyświetlane ćwiczenia  mogą być nieaktualne."
        );
      });
    var docRef = db.collection("users").doc(this.uid);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.changeDoneTasks(doc.data());
          this.changeScores(doc.data().scores);
          const group = doc.data().group;
          this.group = group;
          this.changeGroup(group);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });

    if (!localStorage.notFirstTime) {
      this.$nextTick(function() {
        this.welcomeDialog = localStorage.notFirstTime;
        localStorage.notFirstTime = true;
      });
    }
  },
};
</script>
